import Forest from '../images/forest.png';
import Grad from '../images/grad.jpeg'
import Et from '../images/et.png';
import uni from '../images/uni.jpg';
import FadeInSection from './FadeIn';
import reportPDF from '../Report.pdf';
import forestimg from '../images/forestimg.png';
import forestImg2 from '../images/forestImg2.png';
import Video from '../images/projectVideo.mp4';
import Footer from '../components/Footer';
import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";

const RenderMain = () => {
  return(
    <div id="main-body" className="main-body-about">
      <div className="about-box">
        <div className="overflow-hidden"></div>
          <img className="grad-img" alt="img" src={Grad} />
        
        <div class="slide-right">
          <h2> 
            I'm Callum and I've recently just graduated at the University Of Hull, studying Computer Science!</h2>
        </div>
      </div>
      <div className="about-box-more">
        <div className="overflow-hidden">
        <div className="year">
          2016<br/>- 2020
        </div>
        </div>
        <div class="slide-left">
          <h2>
            During this time I studied many modules, but what stood out to me the most was 
            Software and VR development. <br/><br/>So much in fact, I developed a VR game for my 
            dissertation project which I would love to explain more about, if you'd like to scroll down...
          </h2>
        </div>
      </div>
    </div>
  )
}

const ForestImage = () => {
    return(
        <div>
            <img className="forest-img" alt="img"src={Forest} /> 
        </div>
    )
}

const EtImage = () => {
    return(
        <div>
            <img className="image-et" alt="img" data-scroll="true" data-scroll-speed="-20" data-scroll-direction="horizontal" src={Et} /> 
        </div>
    )
}

const AboutMe = () => {

  let videoAspect;

  if (isTablet || isMobile){
    videoAspect = <div className="video-responsive">
                    <video width="50%"
                        height="50%" controls>
                        <source src={Video} type="video/mp4"/>
                    </video>
                  </div>;
  }
  else {
    videoAspect = <div className="video-responsive">
                    <video width="50%"
                        height="30%" controls>
                        <source src={Video} type="video/mp4"/>
                    </video>
                  </div>
  }
    return(
    <FadeInSection>
    <div className="info-box flex">
      <h1 className="text-info text-info-forest">
        -Forest Hero Quest-<br/>
         <div class="slide-left" data-scroll="true" data-scroll-speed="1" >
          
          <h2 className='forest-info-text'>‘Forest Hero Quest’, is a fun virtual reality game that 
            immerses a user into a forest environment. The game is 
            to use sense of direction to walk through the paths, 
            collect items and return back to proceed onto the next level, 
            with the aim to create a fun experience and also potentially 
            contribute to dementia research.
          </h2>
          <img className="project-img" alt="img" src={forestimg} />
          
        </div>
        <a className='pdfLink' href={reportPDF} data-scroll="true" data-scroll-speed="1">Download my project report</a>
        <div class="slide-left" data-scroll="true" data-scroll-speed="1" >
          <img className="project-img2" alt="img" src={forestImg2}/>
          <h2 className='forest-info-text2'>Inspired by <a className='sea-hero' href="https://glitchers.com/project/sea-hero-quest-vr/">Sea Hero Quest </a>, the game in which players provide 
            the research to help scientists understand how diseases like Alzheimer’s 
            affect the brain, this project will attempt to create a virtual reality 
            experience to challenge the player to access important parts of their brain 
            such as navigation and spatial memory whilst taking pleasure making their 
            way through a fantasy forest. 
          </h2>
          <h2 className='video-label'>
            Below is a video that was submitted for my final mark, hit play if you wish to learn more!
          </h2>
         {videoAspect}
        </div>
        
      </h1>
    </div>
    <Footer/>
    </FadeInSection>
    )
}

function About() {
  
  return(
    <div className="main overflow-hidden">
      <RenderMain/>
      <EtImage/>
      <h1 className="text-fixed" data-scroll="true" data-scroll-speed="-11" data-scroll-direction="vertical">Enter into the far reaches of a magical forest where you will find illuminous plants to help guide you in the darkness,
         in my project 'Forest Hero Quest' developed for VR.
        </h1>
      <div className="fixed-image-spacing"/>
      <div>
          <ForestImage/>
          <div className="main-content">
              <AboutMe/>
          </div>
      </div>
    </div>
  );
}

export default About;