import Home from './pages/homePage';
import About from './pages/about';
import './scss/header.scss';
import './scss/body.scss';
import './scss/buttons.scss';
import './scss/about.scss';
import {HashRouter, Route, Switch} from 'react-router-dom';

export default function App() {  

  return (
    <HashRouter>
      <div className="App">
        <Switch>
          <Route exact path='/'>
            <Home/>
          </Route>
          <Route path='/about'>
            <About/>
          </Route>
        </Switch>
      </div>
    </HashRouter>
  );
}
