import React from "react";
import { SocialIcon } from 'react-social-icons';
const LinkedIn = <SocialIcon className='button-fit' url='https://www.linkedin.com/in/callum-naylor-bb6b901b1/' label="LinkedIn"/>
const Email = <SocialIcon className='button-fit' network="email" label="Gmail" bgColor="#e0611c"/>


const style = {
  border: '2px solid orange'
  };

const PulsingButtons = () => {
  return (
    <div className='button-area' >
    <a className='btn-linkedin' data-scroll="true" data-scroll-speed="5" href="https://www.linkedin.com/in/callum-naylor-bb6b901b1/">
      <div className="pulsing floating"> 
        {LinkedIn}
      </div>
    </a>
    <a className='btn-mail' data-scroll="true" data-scroll-speed="5" href="mailto:callumnaylor7@gmail.com">
      <div className="pulsing-mail floating" > 
        {Email}
      </div>
    </a>
    </div>
  )
}

export default PulsingButtons;