import Header from '../components/Header';
import Body from '../components/Body';
import React from "react";
import HomeWithScroll from '../components/HomeWithScroll';

import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";

export default function HomePage() {

 const RenderContent = () => {
    if (isMobile || isTablet) {
      return( 
      <div>
        <Header/>
        <Body/>
      </div>
    )}
    if(isDesktop){
      return(
      <HomeWithScroll/>
    )}
}
  return(
    <div>
      <RenderContent/>
    </div>
  );
}