import React from "react";
import dark from '../images/nightImage.jpg';
import light from '../images/lightimage.jpg';

const Images = () => {

  return (
    <div>
      <img className="main-body" alt="img"src={light} />
      <img className="main-body top" alt="img" src={dark} />
    </div>
  )
}

export default Images;