const Footer = () => {
  return(
    <div className="footer">
      <div className="right">Portfolio 2021
        <p className="rightExt">Designed and created by me</p>
      </div>
    </div>
  )
}

export default Footer;