import Header from '../components/Header';
import About from '../components/About';
import Moon from '../components/Moon';
import React from "react";
import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  isMobile,
  isTablet,
  isDesktop
} from "react-device-detect";
import AboutWithScroll from '../components/AboutWithScroll';

export default function AboutMePage() {   

 const RenderContent = () => {
    if (isMobile || isTablet) {
        return( 
        <div>
          <Moon/>
          <Header/>
          <About/>
        </div>
      )}
    if(isDesktop){
    return(<AboutWithScroll/>)
  }
}
  return <RenderContent/>;
}