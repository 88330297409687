import locomotiveScroll from "locomotive-scroll";
import Header from '../components/Header';
import About from '../components/About';
import Moon from '../components/Moon';
import React, {useEffect} from "react";

export default function AboutWithScroll() {
 
 const scrollRef = React.createRef();
    let scroll = new locomotiveScroll();

  useEffect(() => {
    scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smartphone: {
        smooth: true
    },
    tablet: {
        smooth: true
    }
    });

    // `useEffect`'s cleanup phase
    return () => scroll.destroy();
  });

  function locomotiveHeightBug(){
  scroll.update();
}

setTimeout(locomotiveHeightBug, 2000);

 return(
    <div>
      <Moon/>
      <div className="scroll" ref={scrollRef}>
        <Header/>
        <About/>
      </div>
    </div>
)
}
