import React, {useEffect} from "react";
import locomotiveScroll from "locomotive-scroll";
import Header from '../components/Header';
import Body from '../components/Body';

export default function HomeWithScroll() {
 
 const scrollRef = React.createRef();
    let scroll = new locomotiveScroll();

  useEffect(() => {
    scroll = new locomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      smartphone: {
        smooth: true
    },
    tablet: {
        smooth: true
    }
    });

    // `useEffect`'s cleanup phase
    return () => scroll.destroy();
  });

  function locomotiveHeightBug(){
  scroll.update();
}

setTimeout(locomotiveHeightBug, 2000);

 return(
      <div>
        <div className="scroll" ref={scrollRef}> 
          <Header/>
          <Body/>
        </div>
      </div>
    )
}