import Images from './Image';
import bytron from '../images/bytron.png';
import ops from '../images/opsboard.jpg';
import etops from '../images/etops.jpg';
import reactImage from '../images/react.png';
import plane from '../images/plane.png';
import PulsingButtons from "./PulsingButtons";
import React from "react";
import FadeInSection from "./FadeIn";
import Footer from '../components/Footer';
 
const Text = () => {
  return(
    <div className="title-box">
      <h1 className="text-title">
        Hi! I'm Callum, <br/> a Software Developer.
      </h1>
      <p className="text-sub">Keen to develop on front-end to create engaging UI </p>
    </div>
  )
}

const BytronText = () => {
  return(
    <FadeInSection>
    <div className="info-box flex">
      <h1 className="text-info">
        I'm a Junior Software Developer <br/>working remotely for 
        <a className='bytron-link' href="https://www.bytron.aero/"> Bytron Aviation Systems</a>
        
        <p className="text-sub padding">
          <p>I've spent over a year developing
         and training in languages such as PHP, Javascript and React whilst working 
         on the skybook software. <br></br><br></br>
          </p>
          <p>This has progressed me onto learning both back-end processes
         such as PHP and building applications in front-end using React JS to 
         produce features for our customers at Bytron. <br></br><br></br>
          </p>
         <p>Out of the office, you'll find me 
         playing games, at the gym, kicking a football around with friends and hitting a small ball
         into a tiny hole with a flag. <br></br><br></br>
         </p>
        </p>
      </h1>
      <img className="bytron-img" alt="bytron" src={bytron}/>
    </div>
    </FadeInSection>
  )
}

const BytronTextMore = () => {
  return(
    <FadeInSection>
    <div className="info-box">
      <img className="plane" alt="bytron" data-scroll="true" data-scroll-speed="-10" data-scroll-direction="horizontal" src={plane}/>
       <p className="text-sub padding black">Examples of the projects I have been closely working on the
       front-end, developed in React</p>
       <p style={{fontSize:8}}></p>
      
      <img className="img-examples" alt="bytron" src={etops}/>
      <img className="img-examples" alt="bytron" src={ops}/>
    </div>
    </FadeInSection>
  )
}

const AboutMeSection= () => {

  const scrollToTop = () => {
  window.scrollTo(0,0); 
}
  return(
    <FadeInSection>
    <div className="info-box padding-bottom">
       <p className="text-sub padding black">This is my little playground and I'm passionate to develop more!</p>
        <a className="buttonCircle" href="/#/about" onClick={scrollToTop}>About Me</a>
        <img className="react-img" alt="img" src={reactImage}/>
    </div>
    </FadeInSection>
  )
}

const RenderBody = () => {
  return(
    <div id="main-body" className="main-body">
        <Images/>
        <Text/>
        <PulsingButtons/>
         <div class="loader">
          <div class="bouncingBall"></div>
      </div>
    </div>
  )
}

function Body() {
  return(
    <div className="main">
      <RenderBody/>
      <div id="main-content" className="main-content">
        <BytronText/>
      </div>
      <div className="main-content2">
        <BytronTextMore/>
        <AboutMeSection/>
        <Footer/>
      </div>      
    </div>
  );
}

export default Body;
