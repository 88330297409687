import Moon from '../images/moon.jpg';
const MoonImage = () => {
    //Photo by <a href="https://unsplash.com/@leandrarieger?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Leandra Rieger</a> on <a href="https://unsplash.com/s/photos/moonlight?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
    return(
    <div className="text-fixed-box">
       
        <img className="image-fixed" alt="img"src={Moon} /> 
        
    </div>
    )
}

export default MoonImage;